// import { CardSwiperUser } from '@/components/card-swipe-user';
import { useGetProfilesAll } from '@/hooks/use-get-profiles-all';
import { Spiner } from '@/ui-kit/';
import { CardSwiperUserNew } from '@/components/card-swiper-user-new';
import { useEffect, useRef } from 'react';
import { Profile } from '@/types/profile';
import _ from 'lodash';

export function Browse() {
    const { data, isFetching, refetch } = useGetProfilesAll();

    const prevUsers = useRef<Profile[]>([]);

    const usersList = data?.data;

    useEffect(() => {
        prevUsers.current = usersList ?? [];
    }, [usersList]);

    const displayedUsers = usersList?.filter((user) => !prevUsers.current.find((prevUser) => prevUser.id === user.id));

    if (isFetching) return <Spiner />;

    return (
        <CardSwiperUserNew
            users={displayedUsers ?? []}
            refetchData={refetch}
        />
    );
}
