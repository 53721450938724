import { Typography, Uploader } from '@/ui-kit';
import styles from './upload-photo-registartion-form.module.scss';
import { Profile } from '@/types/profile';
import { usePhotoUpload } from '@/hooks/use-photo-upload';

import { ButtonSubmitStep } from '../button-submit-step';
import React, { useMemo, useState } from 'react';
import { useDeletePhotoById } from '@/hooks/use-delete-photo-by-id';
import { useAuth } from '@/store/useAuth';
interface Props {
    userData?: Profile | null;
}
export const UploadPhotoRegistartionForm: React.FC<Props> = ({ userData }) => {
    const { mutateAsync: uploadPhoto } = usePhotoUpload();
    const { mutateAsync: deletePhotoById } = useDeletePhotoById();
    const setUserData = useAuth((state) => state.setProfile);

    const [progress, setProgress] = useState(0);

    const isPhotoUploading = !!progress;

    const photoCount = useMemo(() => {
        return userData?.photos?.length ?? 0;
    }, [userData?.photos?.length]);
    const [isDeleteLoading, setIsDeleteLoading] = useState(false);

    const handleUploadPhoto = async (file: File | null) => {
        if (!file) return;

        try {
            const formData = new FormData();
            formData.append('photo[image]', file);
            const { data } = await uploadPhoto({ dataPhoto: formData, callback: setProgress });
            setUserData({ ...userData, photos: [...(userData?.photos ?? []), data] });
        } finally {
            setProgress(0);
        }
    };

    const handleDeletePhoto = async (photoId?: number) => {
        if (!photoId || photoCount === 1 || isDeleteLoading) return;
        setIsDeleteLoading(true);
        try {
            const res = await deletePhotoById(photoId);
            if (res.status === 200) {
                setUserData({ ...userData, photos: userData?.photos?.filter((photo) => photo.id !== photoId) });
            }
        } catch (error) {
            console.error(`Error in ${handleDeletePhoto.name}:`, error);
        } finally {
            setIsDeleteLoading(false);
        }
    };

    return (
        <form className={styles.form}>
            <div className={styles.formContainer}>
                <Typography
                    variant="p"
                    fontWeight="medium"
                    className={styles.titleForm}
                    center
                >
                    Загрузите фотографии
                </Typography>
                <div className={styles.formLabel}>
                    <Typography
                        center
                        variant="p"
                        fontWeight="medium"
                        typeColor="gray-label"
                    >
                        Минимум одно, а лучше – все шесть.
                        <br />
                        Фото должны быть реальными!
                    </Typography>
                </div>
                <div className={styles.uploaderBlok}>
                    {userData?.photos.map((photo, index) => (
                        <Uploader
                            key={photo.id}
                            photoUrl={photo.thumb_url}
                            onDeletePhoto={() => handleDeletePhoto(userData?.photos[index]?.id)}
                            isDeleteLoading={isDeleteLoading}
                            photoCount={photoCount}
                        />
                    ))}

                    {Array.from({ length: 6 - (userData?.photos.length ?? 0) }).map((_, index) => {
                        const isActive = !index;

                        return (
                            <Uploader
                                key={Math.random()}
                                isActive={isActive}
                                isUploading={isPhotoUploading && isActive}
                                progress={progress}
                                onChange={handleUploadPhoto}
                            />
                        );
                    })}
                </div>
            </div>
            <ButtonSubmitStep
                stepNumber={2}
                isDisabled={!photoCount}
                href="/registration/step/3"
                isGray
            />
        </form>
    );
};
