import React from 'react';
import { Typography } from '@/ui-kit';
import styles from './progress.module.scss';

interface Props {
    progress: number;
    size?: number;
    color?: string;
    withText?: boolean;
}

export const Progress: React.FC<Props> = ({ progress, size = 50, color = '#F26A1C', withText = false }) => {
    const radius = size / 2; // Радиус круга
    const strokeWidth = 6; // Толщина линии
    const normalizedRadius = radius - strokeWidth / 2;
    const circumference = 2 * Math.PI * normalizedRadius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
        <div className={styles.progress}>
            <svg
                height={radius * 2}
                width={radius * 2}
                className={styles.progressSvg}
            >
                <circle
                    className={styles.progressCircle}
                    stroke={color}
                    fill="transparent"
                    strokeWidth={strokeWidth}
                    strokeDasharray={circumference}
                    strokeDashoffset={strokeDashoffset}
                    r={normalizedRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
            {withText && (
                <Typography
                    className={styles.progressText}
                    typeColor="orange"
                    fontWeight="medium"
                    center
                >
                    {progress}%
                </Typography>
            )}
        </div>
    );
};
